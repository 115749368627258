
import Vue, {PropType} from "vue";
import {ExcelProductModel} from "kistenkonfigurator/build/excel/ExcelProductModel";
import {ConfigurationDataFlat} from "kistenkonfigurator/build/property/configuration";
import {AlternateRenderingPropertyRoot} from "kistenkonfigurator/build/product/AlternateRenderingProductModel";
import {ProductModelFactory} from "../../../model/product/ProductModelFactory";
import {FlatProductConfigurations, ProductConfiguratorUIModel} from "../../../model/ProductBusinessLogic";
import {Product} from "../../../model/rest/data-contracts";

export interface ConfigurationChangedEvent {
  userConfiguration: ConfigurationDataFlat;
  configuratorConfiguration: FlatProductConfigurations;
  productModel: ExcelProductModel;
  productImageUrl: string;
}

interface State {
  product: Product;
  productConfiguratorModel: ProductConfiguratorUIModel;
}

export default Vue.extend({
  components: {
    ProductConfigurator: () => import("../../../components/configurator/ProductConfigurator.vue"),
  },
  props: {
    propertyRoot: {
      type: String as PropType<AlternateRenderingPropertyRoot>,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data(): State {
    return {
      product: ProductModelFactory.createEmptyOverviewProduct(),
      productConfiguratorModel: {
        configuratorConfiguration: {
          productConfiguration: {},
          completeConfiguration: {},
        },
        configuration: {},
      },
    };
  },
  methods: {
    dialog(): any {
      return this.$refs.dialog as any;
    },
    configurator(): any {
      return this.$refs.configurator as any;
    },
    /**
     * @param {Product} product
     * @param {ConfigurationDataFlat} configuration
     */
    open(product: Product, configuration: ConfigurationDataFlat): void {
      this.product = product;
      this.productConfiguratorModel = {
        configuration,
        configuratorConfiguration: {
          productConfiguration: {},
          completeConfiguration: {},
        },
      };
      this.dialog().show();
    },
    async okClicked(): Promise<void> {
      const imageData = await this.configurator().generateProductImage();

      const productModel = this.configurator().getCurrentProductModel();
      const event: ConfigurationChangedEvent = {
        userConfiguration: this.productConfiguratorModel.configuration,
        configuratorConfiguration: this.productConfiguratorModel.configuratorConfiguration,
        productModel,
        productImageUrl: imageData,
      };

      this.$emit("onConfigurationChanged", event);
      this.cancelClicked();
    },
    cancelClicked(): void {
      this.dialog().hide();
      this.closeClicked();
    },
    closeClicked() {
      this.product = ProductModelFactory.createEmptyOverviewProduct();
      this.productConfiguratorModel = {
        configuratorConfiguration: {
          productConfiguration: {},
          completeConfiguration: {},
        },
        configuration: {},
      };
    },
  },
});
