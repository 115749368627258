import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4e2163c0 = () => interopDefault(import('../pages/-moebel/index.vue' /* webpackChunkName: "pages/-moebel/index" */))
const _9954bfa8 = () => interopDefault(import('../pages/-verpackung/index.vue' /* webpackChunkName: "pages/-verpackung/index" */))
const _60df6e19 = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _295d3ddc = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _6d7ff186 = () => interopDefault(import('../pages/admin/Attribute.vue' /* webpackChunkName: "pages/admin/Attribute" */))
const _a7e827f8 = () => interopDefault(import('../pages/admin/Category.vue' /* webpackChunkName: "pages/admin/Category" */))
const _5b3d4e47 = () => interopDefault(import('../pages/admin/customer/index.vue' /* webpackChunkName: "pages/admin/customer/index" */))
const _5085cbce = () => interopDefault(import('../pages/admin/Media.vue' /* webpackChunkName: "pages/admin/Media" */))
const _7d5549eb = () => interopDefault(import('../pages/admin/Orders.vue' /* webpackChunkName: "pages/admin/Orders" */))
const _12491ebc = () => interopDefault(import('../pages/admin/product/index.vue' /* webpackChunkName: "pages/admin/product/index" */))
const _6947dcb3 = () => interopDefault(import('../pages/admin/ProductType.vue' /* webpackChunkName: "pages/admin/ProductType" */))
const _c69e4106 = () => interopDefault(import('../pages/admin/Quotes.vue' /* webpackChunkName: "pages/admin/Quotes" */))
const _9b045eae = () => interopDefault(import('../pages/admin/Settings.vue' /* webpackChunkName: "pages/admin/Settings" */))
const _22815ddc = () => interopDefault(import('../pages/admin/category/AddCategoryProductModal.vue' /* webpackChunkName: "pages/admin/category/AddCategoryProductModal" */))
const _96d7584c = () => interopDefault(import('../pages/admin/category/EditCategoryModal.vue' /* webpackChunkName: "pages/admin/category/EditCategoryModal" */))
const _4fde7fc3 = () => interopDefault(import('../pages/admin/category/EditProductModal.vue' /* webpackChunkName: "pages/admin/category/EditProductModal" */))
const _3420c557 = () => interopDefault(import('../pages/admin/customer/create.vue' /* webpackChunkName: "pages/admin/customer/create" */))
const _7e9358bc = () => interopDefault(import('../pages/admin/order/TruckCapacity.vue' /* webpackChunkName: "pages/admin/order/TruckCapacity" */))
const _65a76458 = () => interopDefault(import('../pages/admin/customer/_id/address.vue' /* webpackChunkName: "pages/admin/customer/_id/address" */))
const _f832914c = () => interopDefault(import('../pages/admin/customer/_id/edit.vue' /* webpackChunkName: "pages/admin/customer/_id/edit" */))
const _2b434878 = () => interopDefault(import('../pages/admin/customer/_id/media.vue' /* webpackChunkName: "pages/admin/customer/_id/media" */))
const _8e5313a2 = () => interopDefault(import('../pages/admin/product/_id/delivery.vue' /* webpackChunkName: "pages/admin/product/_id/delivery" */))
const _6883d276 = () => interopDefault(import('../pages/admin/product/_id/edit.vue' /* webpackChunkName: "pages/admin/product/_id/edit" */))
const _0d4073b4 = () => interopDefault(import('../pages/admin/product/_id/internal-price.vue' /* webpackChunkName: "pages/admin/product/_id/internal-price" */))
const _c51a2c8e = () => interopDefault(import('../pages/admin/product/_id/media.vue' /* webpackChunkName: "pages/admin/product/_id/media" */))
const _462bcc84 = () => interopDefault(import('../pages/admin/product/_id/price2.vue' /* webpackChunkName: "pages/admin/product/_id/price2" */))
const _fbdfb86e = () => interopDefault(import('../pages/agb/index.vue' /* webpackChunkName: "pages/agb/index" */))
const _a6570028 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _65773522 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _2591c3e8 = () => interopDefault(import('../pages/checkout/completed.vue' /* webpackChunkName: "pages/checkout/completed" */))
const _61bda7e4 = () => interopDefault(import('../pages/checkout/delivery-address.vue' /* webpackChunkName: "pages/checkout/delivery-address" */))
const _49402d0a = () => interopDefault(import('../pages/checkout/invoice.vue' /* webpackChunkName: "pages/checkout/invoice" */))
const _20d10763 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _0a200d17 = () => interopDefault(import('../pages/checkout/payment-failed.vue' /* webpackChunkName: "pages/checkout/payment-failed" */))
const _7e589ed5 = () => interopDefault(import('../pages/checkout/signin.vue' /* webpackChunkName: "pages/checkout/signin" */))
const _3587ad03 = () => interopDefault(import('../pages/checkout/summary.vue' /* webpackChunkName: "pages/checkout/summary" */))
const _49f6ad30 = () => interopDefault(import('../pages/datenschutz/index.vue' /* webpackChunkName: "pages/datenschutz/index" */))
const _2d88729c = () => interopDefault(import('../pages/favoriten.vue' /* webpackChunkName: "pages/favoriten" */))
const _83f8d7c2 = () => interopDefault(import('../pages/favoriten/index.vue' /* webpackChunkName: "pages/favoriten/index" */))
const _650ba346 = () => interopDefault(import('../pages/favoriten/meine-produkte.vue' /* webpackChunkName: "pages/favoriten/meine-produkte" */))
const _8936860a = () => interopDefault(import('../pages/favoriten/quick-order.vue' /* webpackChunkName: "pages/favoriten/quick-order" */))
const _b4195186 = () => interopDefault(import('../pages/favoriten/TotalValueStatusPanel.js' /* webpackChunkName: "pages/favoriten/TotalValueStatusPanel" */))
const _275abb49 = () => interopDefault(import('../pages/favoriten/zuletzt-bestellt.vue' /* webpackChunkName: "pages/favoriten/zuletzt-bestellt" */))
const _1d03952e = () => interopDefault(import('../pages/geschichte/index.vue' /* webpackChunkName: "pages/geschichte/index" */))
const _58cbd004 = () => interopDefault(import('../pages/impressum/index.vue' /* webpackChunkName: "pages/impressum/index" */))
const _23b2b826 = () => interopDefault(import('../pages/kontakt/index.vue' /* webpackChunkName: "pages/kontakt/index" */))
const _3436f068 = () => interopDefault(import('../pages/labels/index.vue' /* webpackChunkName: "pages/labels/index" */))
const _08f54dbc = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _41a86b05 = () => interopDefault(import('../pages/-moebel/gallerie.vue' /* webpackChunkName: "pages/-moebel/gallerie" */))
const _42ba156b = () => interopDefault(import('../pages/-verpackung/dienstleistungen.vue' /* webpackChunkName: "pages/-verpackung/dienstleistungen" */))
const _3bc4c0a4 = () => interopDefault(import('../pages/-verpackung/dienstleistungen/index.vue' /* webpackChunkName: "pages/-verpackung/dienstleistungen/index" */))
const _278f9500 = () => interopDefault(import('../pages/-verpackung/dienstleistungen/beratung.vue' /* webpackChunkName: "pages/-verpackung/dienstleistungen/beratung" */))
const _71c31c42 = () => interopDefault(import('../pages/-verpackung/dienstleistungen/gefahrgut.vue' /* webpackChunkName: "pages/-verpackung/dienstleistungen/gefahrgut" */))
const _3076b63a = () => interopDefault(import('../pages/-verpackung/dienstleistungen/lagern.vue' /* webpackChunkName: "pages/-verpackung/dienstleistungen/lagern" */))
const _f0080588 = () => interopDefault(import('../pages/-verpackung/dienstleistungen/logistik.vue' /* webpackChunkName: "pages/-verpackung/dienstleistungen/logistik" */))
const _0f5faef6 = () => interopDefault(import('../pages/-verpackung/dienstleistungen/transport.vue' /* webpackChunkName: "pages/-verpackung/dienstleistungen/transport" */))
const _c08ffd7e = () => interopDefault(import('../pages/-verpackung/dienstleistungen/verpacken.vue' /* webpackChunkName: "pages/-verpackung/dienstleistungen/verpacken" */))
const _6b469ab5 = () => interopDefault(import('../pages/-verpackung/galerie.vue' /* webpackChunkName: "pages/-verpackung/galerie" */))
const _13137e28 = () => interopDefault(import('../pages/user/customer.vue' /* webpackChunkName: "pages/user/customer" */))
const _2915086a = () => interopDefault(import('../pages/user/login.vue' /* webpackChunkName: "pages/user/login" */))
const _419ae8ea = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _8691abac = () => interopDefault(import('../pages/user/profile/bestellungen.vue' /* webpackChunkName: "pages/user/profile/bestellungen" */))
const _89a191fe = () => interopDefault(import('../pages/user/profile/konto.vue' /* webpackChunkName: "pages/user/profile/konto" */))
const _0608c716 = () => interopDefault(import('../pages/user/profile/offerten.vue' /* webpackChunkName: "pages/user/profile/offerten" */))
const _5d091b22 = () => interopDefault(import('../pages/user/profile/zugangsdaten.vue' /* webpackChunkName: "pages/user/profile/zugangsdaten" */))
const _5b020531 = () => interopDefault(import('../pages/user/register.vue' /* webpackChunkName: "pages/user/register" */))
const _18f90aa9 = () => interopDefault(import('../pages/user/request-password-reset.vue' /* webpackChunkName: "pages/user/request-password-reset" */))
const _4cfcc467 = () => interopDefault(import('../pages/user/reset-password.vue' /* webpackChunkName: "pages/user/reset-password" */))
const _179d09e6 = () => interopDefault(import('../pages/checkout-quote/_uuid.vue' /* webpackChunkName: "pages/checkout-quote/_uuid" */))
const _ee225e10 = () => interopDefault(import('../pages/products/_articleNumber/index.vue' /* webpackChunkName: "pages/products/_articleNumber/index" */))
const _2da9a914 = () => interopDefault(import('../pages/produkte/_.vue' /* webpackChunkName: "pages/produkte/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/-moebel",
    component: _4e2163c0,
    name: "-moebel"
  }, {
    path: "/-verpackung",
    component: _9954bfa8,
    name: "-verpackung"
  }, {
    path: "/admin",
    component: _60df6e19,
    children: [{
      path: "",
      component: _295d3ddc,
      name: "admin"
    }, {
      path: "Attribute",
      component: _6d7ff186,
      name: "admin-Attribute"
    }, {
      path: "Category",
      component: _a7e827f8,
      name: "admin-Category"
    }, {
      path: "customer",
      component: _5b3d4e47,
      name: "admin-customer"
    }, {
      path: "Media",
      component: _5085cbce,
      name: "admin-Media"
    }, {
      path: "Orders",
      component: _7d5549eb,
      name: "admin-Orders"
    }, {
      path: "product",
      component: _12491ebc,
      name: "admin-product"
    }, {
      path: "ProductType",
      component: _6947dcb3,
      name: "admin-ProductType"
    }, {
      path: "Quotes",
      component: _c69e4106,
      name: "admin-Quotes"
    }, {
      path: "Settings",
      component: _9b045eae,
      name: "admin-Settings"
    }, {
      path: "category/AddCategoryProductModal",
      component: _22815ddc,
      name: "admin-category-AddCategoryProductModal"
    }, {
      path: "category/EditCategoryModal",
      component: _96d7584c,
      name: "admin-category-EditCategoryModal"
    }, {
      path: "category/EditProductModal",
      component: _4fde7fc3,
      name: "admin-category-EditProductModal"
    }, {
      path: "customer/create",
      component: _3420c557,
      name: "admin-customer-create"
    }, {
      path: "order/TruckCapacity",
      component: _7e9358bc,
      name: "admin-order-TruckCapacity"
    }, {
      path: "customer/:id/address",
      component: _65a76458,
      name: "admin-customer-id-address"
    }, {
      path: "customer/:id/edit",
      component: _f832914c,
      name: "admin-customer-id-edit"
    }, {
      path: "customer/:id/media",
      component: _2b434878,
      name: "admin-customer-id-media"
    }, {
      path: "product/:id/delivery",
      component: _8e5313a2,
      name: "admin-product-id-delivery"
    }, {
      path: "product/:id/edit",
      component: _6883d276,
      name: "admin-product-id-edit"
    }, {
      path: "product/:id/internal-price",
      component: _0d4073b4,
      name: "admin-product-id-internal-price"
    }, {
      path: "product/:id/media",
      component: _c51a2c8e,
      name: "admin-product-id-media"
    }, {
      path: "product/:id/price2",
      component: _462bcc84,
      name: "admin-product-id-price2"
    }]
  }, {
    path: "/agb",
    component: _fbdfb86e,
    name: "agb"
  }, {
    path: "/checkout",
    component: _a6570028,
    children: [{
      path: "",
      component: _65773522,
      name: "checkout"
    }, {
      path: "completed",
      component: _2591c3e8,
      name: "checkout-completed"
    }, {
      path: "delivery-address",
      component: _61bda7e4,
      name: "checkout-delivery-address"
    }, {
      path: "invoice",
      component: _49402d0a,
      name: "checkout-invoice"
    }, {
      path: "payment",
      component: _20d10763,
      name: "checkout-payment"
    }, {
      path: "payment-failed",
      component: _0a200d17,
      name: "checkout-payment-failed"
    }, {
      path: "signin",
      component: _7e589ed5,
      name: "checkout-signin"
    }, {
      path: "summary",
      component: _3587ad03,
      name: "checkout-summary"
    }]
  }, {
    path: "/datenschutz",
    component: _49f6ad30,
    name: "datenschutz"
  }, {
    path: "/favoriten",
    component: _2d88729c,
    children: [{
      path: "",
      component: _83f8d7c2,
      name: "favoriten"
    }, {
      path: "meine-produkte",
      component: _650ba346,
      name: "favoriten-meine-produkte"
    }, {
      path: "quick-order",
      component: _8936860a,
      name: "favoriten-quick-order"
    }, {
      path: "TotalValueStatusPanel",
      component: _b4195186,
      name: "favoriten-TotalValueStatusPanel"
    }, {
      path: "zuletzt-bestellt",
      component: _275abb49,
      name: "favoriten-zuletzt-bestellt"
    }]
  }, {
    path: "/geschichte",
    component: _1d03952e,
    name: "geschichte"
  }, {
    path: "/impressum",
    component: _58cbd004,
    name: "impressum"
  }, {
    path: "/kontakt",
    component: _23b2b826,
    name: "kontakt"
  }, {
    path: "/labels",
    component: _3436f068,
    name: "labels"
  }, {
    path: "/news",
    component: _08f54dbc,
    name: "news"
  }, {
    path: "/-moebel/gallerie",
    component: _41a86b05,
    name: "-moebel-gallerie"
  }, {
    path: "/-verpackung/dienstleistungen",
    component: _42ba156b,
    children: [{
      path: "",
      component: _3bc4c0a4,
      name: "-verpackung-dienstleistungen"
    }, {
      path: "beratung",
      component: _278f9500,
      name: "-verpackung-dienstleistungen-beratung"
    }, {
      path: "gefahrgut",
      component: _71c31c42,
      name: "-verpackung-dienstleistungen-gefahrgut"
    }, {
      path: "lagern",
      component: _3076b63a,
      name: "-verpackung-dienstleistungen-lagern"
    }, {
      path: "logistik",
      component: _f0080588,
      name: "-verpackung-dienstleistungen-logistik"
    }, {
      path: "transport",
      component: _0f5faef6,
      name: "-verpackung-dienstleistungen-transport"
    }, {
      path: "verpacken",
      component: _c08ffd7e,
      name: "-verpackung-dienstleistungen-verpacken"
    }]
  }, {
    path: "/-verpackung/galerie",
    component: _6b469ab5,
    name: "-verpackung-galerie"
  }, {
    path: "/user/customer",
    component: _13137e28,
    name: "user-customer"
  }, {
    path: "/user/login",
    component: _2915086a,
    name: "user-login"
  }, {
    path: "/user/profile",
    component: _419ae8ea,
    name: "user-profile",
    children: [{
      path: "bestellungen",
      component: _8691abac,
      name: "user-profile-bestellungen"
    }, {
      path: "konto",
      component: _89a191fe,
      name: "user-profile-konto"
    }, {
      path: "offerten",
      component: _0608c716,
      name: "user-profile-offerten"
    }, {
      path: "zugangsdaten",
      component: _5d091b22,
      name: "user-profile-zugangsdaten"
    }]
  }, {
    path: "/user/register",
    component: _5b020531,
    name: "user-register"
  }, {
    path: "/user/request-password-reset",
    component: _18f90aa9,
    name: "user-request-password-reset"
  }, {
    path: "/user/reset-password",
    component: _4cfcc467,
    name: "user-reset-password"
  }, {
    path: "/checkout-quote/:uuid?",
    component: _179d09e6,
    name: "checkout-quote-uuid"
  }, {
    path: "/products/:articleNumber",
    component: _ee225e10,
    name: "products-articleNumber"
  }, {
    path: "/produkte/*",
    component: _2da9a914,
    name: "produkte-all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
