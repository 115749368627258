
import Vue, {PropType} from "vue";
import {CartModel} from "../../model/checkout/CartModel";
import CartListItem from "./CartListItem.vue";

interface State {
}

export default Vue.extend({
  name: "CartList",
  components: {
    CartListItem,
  },
  props: {
    viewOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
    model: {
      type: Object as PropType<CartModel>,
      required: true,
    },
    isShowInModal: {
      type: Boolean,
      default: true,
    },
  },
  data(): State {
    return {};
  },
});
